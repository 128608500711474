@import 'ember-paper';
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
body, .white {
  background-color: #fff;
}
h3, h2, p {
  font-family: 'Quicksand', serif;
  text-align: center;
}
.p-article {
  font-size: x-large;
}
h1 {
  text-transform: uppercase;
  font-family: 'Sulphur Point', serif;
  padding: 0px 10px;
}
a {
  text-decoration: none;
  color: #434a4a;
}
.logo {
  width: 40%;
  padding: 10px;
  padding-bottom: inherit;
}
.menu {
  background-color: #000000;
}
.menu p {
  font-size: 1em;
}
.p-Time {
  color: #cacaca !important;
}
.grey {
  background-color: #e1e1e1;
}
.darkgrey {
  background-color: #cacaca;
}
.shad {
  box-shadow: 0px 0px 2px 0 #0000009c;
}
.WidthCenter {
  text-align: center;
  width: 100%;
}
.p {
  font-family: 'Quicksand', serif;
  margin: 5px;
  color: #2b2b2b;
  font-size: larger;
}
.result {
  background-color: #aeb5bc;
  padding: 10px;
}
.cartesleft img {
  height: 150px;
  padding: 2px;
}
.cartesleft {
  padding: 10px;
}
.cartesright img {
  height: 90px;
  padding: 2px;
}
.cartesright button {
  padding: inherit;
  margin: inherit;
  border: inherit;
  background-color: transparent;
}
article {
  width: 75%; 
}
.bg-table {
  background-color: #c2e2e654;
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  border: 1px inset #bfcedc;
}
.fondText {
  background-color: #d8d8d8;
  box-shadow: 0 0 3px 0px #00000070;
}
.fondText md-card-content {
  padding: 20px;
}
.md-button {
  background-color: #0083f8;
  color: white;
}
.md-button:hover {
  background-color: #115898 !important;
  color: white;
}
.bold {
  font-weight: bold;
}
span {
  color: #9acd3200;
  font-size: 1px;
}
.bulle-info p, .bulle-info2 p {
  color: #183e20;
}
.bulle-info {
    display: none;
    background-color: #64ff8796;
    padding: 5px;
    position: relative;
    text-align: center;
    border-color: #3b4a61b5;
    border-style: solid;
    border-width: 1.5px;
    height: 70px;
}
.bulle-info2 {
    background-color: #64ff8796;
    padding: 5px;
    width: 25%;
    text-align: center;
    border-color: #3b6142b5;
    border-style: solid;
    border-width: 1.5px;
}
.button-gto {
    background-color: #fff;
    padding: 5px;
    width: 25%;
    text-align: center;
    border-color: #383838b5;
    border-style: solid;
    border-width: 1.5px;
}
.button-gto:hover {
  background-color: #bebebe;
}
/* Conteneur */
.overlay-image {
    display: inline-block;
    position: relative;
}
/* Image */
.overlay-image .image {
 display: block;
 width: 100%;
 height: auto;
}
/* Texte */
.overlay-image .text {
    z-index: 1;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    color: black;
    height: 100%;
    width: 100%;
}
.text p {
  font-size: 1.1em;
}
.text-card {
    z-index: 1;
    position: absolute;
    text-align: center;
    font-size: 2em;
    line-height: 25px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 16%;
    color: transparent;
    height: 55%;
    width: auto;
    padding: 3px;
}
.text-card:hover {
  color: #070707;
  background-color: #ffffff8a;
}

@media screen and (min-width: 200px) and (max-width: 375px) {
  .text {
    line-height: inherit !important;
  }
  article {
    whidth: 95% !important;
  }

}

@media screen and (min-width: 100px) and (max-width: 740px) {
  .cartesleft img {
    height: 108px;
  }
  .menu {
  flex-direction: column;
  }
  .menu p {
    text-align: center;
    padding: inherit !important;
  }
  .flex-25 {
    max-width: inherit !important;
  }
  .cartesleft img {
    padding: 1px;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .cartesleft {
    margin-top: 10px;
  }
  .fondText {
    width: 100%;
  }
}
@media screen and (min-width: 200px) and (max-width: 1110px) {
  article {
    width: 90%; 
  }
  .text {
    line-height: 35px;
  }
  .text p {
    margin: inherit;
  }
  .p-article {
    font-size: 1.2em;
  }
  .media-size {
  font-size: 1em;
  }
  .bulle-info {
    right: 0;
  }
  .ajust-margin1 {
    padding-top: 6%;
  }
  .ajust-margin2 {
    padding-bottom: 6%;
  }

}
@media screen and (min-width: 100px) and (max-width: 1024px) {
  .footer-btn {
    display: none;
  }
  .bulle-info {
    display: block;
    width: 100%;
  }
}
