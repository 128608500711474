// The default item height is also specified in the JavaScript.
$md-autocomplete-item-height: 48px !default;
$md-autocomplete-clear-size: 30px !default;
$md-autocomplete-input-offset: 20px !default;

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px;
  &[disabled] {
    input {
      cursor: default;
    }
  }
  &[md-floating-label] {
    border-radius: 0;
    background: transparent;
    height: auto;

    md-input-container {
      padding-bottom: 0;
    }
    md-autocomplete-wrap {
      height: auto;
    }

    .md-show-clear-button {

      button {
        display: block;
        position: absolute;
        right: 0;
        top: $md-autocomplete-input-offset;
        width: $md-autocomplete-clear-size;
        height: $md-autocomplete-clear-size;
      }

      input {
        // Add padding to the end of the input to avoid overlapping with the clear button.
        @include rtl-prop(padding-right, padding-left, $md-autocomplete-clear-size, 0);
      }
    }

  }
  md-autocomplete-wrap {

    // Layout [layout='row']
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    position: relative;
    overflow: visible;
    height: 40px;
    &.md-menu-showing {
      z-index: $z-index-backdrop + 1;
    }

    md-input-container, input {
      // Layout [flex]
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width : 0;
    }

    md-progress-linear {
      position: absolute;
      bottom: -2px;
      left: 0;
      // When `md-inline` is present, we adjust the offset to go over the `ng-message` space
      &.md-inline {
        bottom: 40px;
        right: 2px;
        left: 2px;
        width: auto;
      }
      .md-mode-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transition: none;

        .md-container {
          transition: none;
          height: 3px;
        }
        &.ng-enter {
          transition: opacity 0.15s linear;
          &.ng-enter-active {
            opacity: 1;
          }
        }
        &.ng-leave {
          transition: opacity 0.15s linear;
          &.ng-leave-active {
            opacity: 0;
          }
        }
      }
    }
  }
  input:not(.md-input) {
    @include md-flat-input();
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
  }
  .md-show-clear-button button {
    position: relative;
    line-height: 20px;
    text-align: center;
    width: $md-autocomplete-clear-size;
    height: $md-autocomplete-clear-size;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 12px;
    background: transparent;
    margin: auto 5px;
    &:after {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: $swift-ease-out;
    }
    &:focus {
      outline: none;

      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
    md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.9);
      path {
        stroke-width: 0;
      }
    }
    &.ng-enter {
      transform: scale(0);
      transition: transform 0.15s ease-out;
      &.ng-enter-active {
        transform: scale(1);
      }
    }
    &.ng-leave {
      transition: transform 0.15s ease-out;
      &.ng-leave-active {
        transform: scale(0);
      }
    }
  }
  // IE Only
  @media screen and (-ms-high-contrast: active) {
    $border-color: #fff;

    input {
      border: 1px solid $border-color;
    }
    li:focus {
      color: #fff;
    }
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container,
.md-standard-list-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(black, 0.25);
  z-index: $z-index-tooltip;

  // Expand the virtualRepeatContainer as much as the max-height from the JavaScript allows.
  // This is necessary for the virtualRepeatContainer to be able to grow back.
  height: 100%;

  .highlight {
    font-weight: bold;
  }
}

.md-standard-list-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
}

.md-virtual-repeat-container.md-not-found,
.md-standard-list-container.md-not-found {
  height: $md-autocomplete-item-height;
}

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0;

  li {
    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: $md-autocomplete-item-height;
    height: $md-autocomplete-item-height;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }

    &:not(.md-not-found-wrapper) {
      cursor: pointer;
    }
  }
}

// IE Only
@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff;
  }
}
