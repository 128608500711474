$switch-width: 36px !default;
$switch-height: $baseline-grid * 3 !default;
$switch-bar-height: 14px !default;
$switch-thumb-size: 20px !default;
$switch-margin: 16px !default;

.md-inline-form {
  md-switch {
    margin-top: 18px;
    margin-bottom: 19px;
  }
}

md-switch {
  margin: $switch-margin 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;

  @include rtl(margin-left, inherit, $switch-margin);
  @include rtl(margin-right, $switch-margin, inherit);

  &:last-of-type {
    @include rtl(margin-left, inherit, 0);
    @include rtl(margin-right, 0, inherit);
  }

  &[disabled] {
    cursor: default;

    .md-container {
      cursor: default;
    }
  }

  .md-container {
    cursor: grab;
    width: $switch-width;
    height: $switch-height;
    position: relative;
    user-select: none;
    @include rtl-prop(margin-right, margin-left, 8px, 0px);
    float: left;
  }

  &.md-inverted .md-container {
    @include rtl(margin-right, initial, 8px);
    @include rtl(margin-left, 8px, initial);
  }

  // If the user moves his mouse off the switch, stil display grabbing cursor
  &:not([disabled]) {
    .md-dragging,
    &.md-dragging .md-container {
      cursor: grabbing;
    }
  }

  &.md-focused {
    .md-thumb:before {
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
    }
  }

  .md-label {
    border-color: transparent;
    border-width: 0;
    float: left;
  }

  .md-bar {
    left: 1px;
    width: $switch-width - 2px;
    top: $switch-height / 2 - $switch-bar-height / 2;
    height: $switch-bar-height;
    border-radius: 8px;
    position: absolute;
  }

  .md-thumb-container {
    top: $switch-height / 2 - $switch-thumb-size / 2;
    left: 0;
    width: $switch-width - $switch-thumb-size;
    position: absolute;
    transform: translate3d(0,0,0);
    z-index: 1;
  }
  &.md-checked .md-thumb-container {
    transform: translate3d(100%,0,0);
  }

  .md-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: $switch-thumb-size;
    width: $switch-thumb-size;
    border-radius: 50%;
    box-shadow: $whiteframe-shadow-1dp;

    &:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto;
    }

    .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -$switch-thumb-size;
      top: -$switch-thumb-size;
      right: -$switch-thumb-size;
      bottom: -$switch-thumb-size;
    }
  }

  &:not(.md-dragging) {
    .md-bar,
    .md-thumb-container,
    .md-thumb {
      transition: $swift-linear;
      transition-property: transform, background-color;
    }
    .md-bar,
    .md-thumb {
      transition-delay: 0.05s;
    }
  }

}

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666;
  }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E;
  }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff;
  }
}
