$tabs-paginator-width: $baseline-grid * 4 !default;
$tabs-tab-width: $baseline-grid * 12 !default;
$tabs-header-height: 48px !default;

@keyframes md-tab-content-hide {
  0% { opacity: 1; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  &:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 200 + $tabs-header-height;
  }
  &[md-align-tabs="bottom"] {
    padding-bottom: $tabs-header-height;
    md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $tabs-header-height;
      z-index: 2;
    }
    md-tabs-content-wrapper {
      top: 0;
      bottom: $tabs-header-height;
    }
  }
  &.md-dynamic-height {
    md-tabs-content-wrapper {
      min-height: 0;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: visible;
    }
    md-tab-content {
      &.md-active {
        position: relative;
      }
    }
  }
  &[md-border-bottom] {
    md-tabs-wrapper {
      border-width: 0 0 1px;
      border-style: solid;
    }
    &:not(.md-dynamic-height) {
      md-tabs-content-wrapper {
        top: $tabs-header-height + 1;
      }
    }
  }
}

md-tabs-wrapper {
  display: block;
  position: relative;
  // transform is needed for iOS Safari to prevent content from disappearing on scroll
  transform: translate(0, 0);
  md-prev-button, md-next-button {
    height: 100%;
    width: $tabs-paginator-width;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: $swift-ease-in-out;
    &:focus {
      outline: none;
    }
    &.md-disabled {
      opacity: 0.25;
      cursor: default;
    }
    &.ng-leave {
      transition: none;
    }
    md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // For RTL tabs, rotate the buttons
    [dir="rtl"] & {
      transform: rotateY(180deg) translateY(-50%);
    }
  }
  md-prev-button {
    @include rtl-prop(left, right, 0, auto);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg==');
  }
  md-next-button {
    @include rtl-prop(right, left, 0, auto);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K');

    // In regular mode, we need to flip the chevron icon to point the other way
    md-icon {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  &.md-stretch-tabs {
    md-pagination-wrapper {
      width: 100%;
      flex-direction: row;
      md-tab-item {
        flex-grow: 1;
      }
    }
  }
}

md-tabs-canvas {
  @include pie-clearfix;
  position: relative;
  overflow: hidden;
  display: block;
  height: $tabs-header-height;
  .md-dummy-wrapper {
    position: absolute;
    top: 0;
    @include rtl-prop(left, right, 0, auto);
  }
  &.md-paginated {
    margin: 0 $tabs-paginator-width;
  }
  &.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center;
    .md-tab {
      float: none;
      display: inline-block;
    }
  }
}

md-pagination-wrapper {
  @include pie-clearfix;
  height: $tabs-header-height;
  display: flex;
  transition: transform $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
  position: absolute;
  @include rtl-prop(left, right, 0, auto);
  transform: translate(0, 0);
  &.md-center-tabs {
    position: relative;
    justify-content: center;
  }
}

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: $tabs-header-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

md-tab-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
  overflow: auto;
  // transform is needed for iOS Safari to prevent content from disappearing on scroll
  transform: translate(0, 0);
  &.md-no-scroll {
    bottom: auto;
    overflow: hidden;
  }
  &.ng-leave, &.md-no-transition {
    transition: none;
  }
  &.md-left:not(.md-active) {
    @include rtl(transform, translateX(-100%), translateX(+100%));
    animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
    visibility: hidden;
    * {
      transition: visibility 0s linear;
      transition-delay: $swift-ease-in-out-duration;
      visibility: hidden;
    }
  }
  &.md-right:not(.md-active) {
    @include rtl(transform, translateX(100%), translateX(-100%));
    animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
    visibility: hidden;
    * {
      transition: visibility 0s linear;
      transition-delay: $swift-ease-in-out-duration;
      visibility: hidden;
    }
  }
  > div {
    flex: 1 0 100%;
    min-width: 0;
    &.ng-leave {
      animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
    }
  }
}

md-ink-bar {
  $duration: $swift-ease-in-out-duration * 0.5;
  $multiplier: 0.5;
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px;
  &.md-left {
    transition: left ($duration * $multiplier) $swift-ease-in-out-timing-function,
        right $duration $swift-ease-in-out-timing-function;
  }
  &.md-right {
    transition: left $duration $swift-ease-in-out-timing-function,
        right ($duration * $multiplier) $swift-ease-in-out-timing-function;
  }
}

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px;
}

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: $tabs-header-height - 24;
  padding: 12px 24px;
  transition: background-color 0.35s $swift-ease-in-out-timing-function;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  @include rtl(float, left, right);
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  &.md-focused, &:focus {
    box-shadow: none;
    outline: none;
  }
  &.md-active {
    cursor: default;
  }
  &.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default;
  }
  &.ng-leave {
    transition: none;
  }
}

md-toolbar + md-tabs, md-toolbar + md-dialog-content md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
