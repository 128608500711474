md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: $icon-size;
  width: $icon-size;

  // The icons should not shrink on smaller viewports.
  min-height: $icon-size;
  min-width: $icon-size;

  svg {
    pointer-events: none;
    display: block;
  }

  &[md-font-icon] {
    line-height: $icon-size;
    width: auto;
  }
}
