.md-searchbox {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.md-searchinput {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

/* TODO is this part of the spec? Demos rely on this. */
.paper-list-inline {
  list-style  : none;
  margin-left : -5px;
  padding-left: 0;
}

.paper-list-inline > li {
  display      : inline-block;
  padding-left : 5px;
  padding-right: 5px;
}

// Icon spinners (not part of angular-material)
.md-spin {
   animation: md-spin 1.5s infinite linear;
}

.md-spin-reverse {
  animation: md-spin-reverse 1.5s infinite linear;
}

// Spin
@keyframes md-spin {
  0% {
    transform: rotate(0deg);
   }
  100% {
    transform: rotate(359deg);
  }
}

// Spin Reverse
@keyframes md-spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}

// color styles
@import 'color-palette';
@import 'default-theme';

// this is an autogenerated file that imports only the needed styles
@import 'ember-paper-components';

// some overrides that are needed for ember-paper (avoid if possible)
@import './overrides/paper-autocomplete';

